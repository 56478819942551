import React from "react";
import Demo from "./Garage.jsx";

export default function Parking() {
  return (
    <div style={{ width: "80%", height: "100%", margin: "auto" }}>
      <div className="hero has-background-grey-light">
        <div style={{ height: "600px" }} className="hero-content">
          <Demo />
        </div>
      </div>
      <div className="container">
        <h1 className="title is-2 has-text-danger">
          Tap or Click the "Random Shift" button to move the car to an empty
          parking space at random !
        </h1>
        <p className="content is-large">What is this?</p>
        <p className="content is-medium">
          This demo uses React-three-fiber to provide a model of a parking
          garage. React-three-fiber is built on three.js. Routes the car can
          take through the garage are determined using a graph data structure.
          The 3D scene was created in blender and converted into a
          fully-functional react component using gltfjsx (A truly challenging
          acronym!). When the Random Shift button is clicked, the application
          starts shifting the car to a random empty space and asynchronously
          sends the current location of the car to an AWS API gateway, which
          sends back the id of the next random empty space. You can inspect the
          request and response in the developer's console.
        </p>
        <ul>
          <li>
            <a
              href="https://github.com/react-spring/react-three-fiber"
              rel="noopener noreferrer"
              className="is-size-4"
            >
              react-three-fiber
            </a>
          </li>
          <li>
            <a
              href="https://github.com/react-spring/gltfjsx"
              rel="noopener noreferrer"
              className="is-size-4"
            >
              gltfjsx
            </a>
          </li>
          <li>
            <a
              href="https://github.com/react-spring/drei"
              rel="noopener noreferrer"
              className="is-size-4"
            >
              drei
            </a>
          </li>
          <li>
            <a
              href="https://www.serverless.com/"
              rel="noopener noreferrer"
              className="is-size-4"
            >
              serverless
            </a>
          </li>
          <li>
            <a
              href="https://aws.amazon.com/lambda/"
              rel="noopener noreferrer"
              className="is-size-4"
            >
              AWS Lambda
            </a>
          </li>
          <li>
            <a
              className="is-size-4"
              href="https://www.blender.org/"
              rel="noopener noreferrer"
            >
              Blender
            </a>
          </li>
        </ul>
        <div>
          Credit to Renderbunny for the awesome{" "}
          <a
            href="https://www.turbosquid.com/3d-models/cartoon-chrysler-car-3d-model-1496394"
            rel="noopener noreferrer"
          >
            Chrysler Model
          </a>
        </div>
      </div>
    </div>
  );
}
