import React from "react";

export default function Contact() {
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <a
            className="box social"
            href="https://github.com/jwhite927"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="title is-2">GitHub</p>
            <p className="subtitle is-4 is-marginless">You'll see my code</p>
          </a>
        </div>
        <div className="column">
          <a
            className="box social"
            href="https://www.linkedin.com/in/jwhite927/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="title is-2">LinkedIn</p>
            <p className="subtitle is-4 is-marginless">
              We'll connect professionally
            </p>
          </a>
        </div>
        <div className="column">
          <a
            className="box social"
            href="mailto:jwhite927@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="title is-2">Email</p>
            <p className="subtitle is-4 is-marginless">
              I'll get back to you promptly
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}
