import React, { useEffect, useRef, useState, Suspense } from "react";
import { Canvas, useLoader, useFrame } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Provider, useSelector, useDispatch } from "react-redux";
import { Billboard } from "drei";

import { BillboardText } from "./BillboardText.jsx";
import { GRAPH, getPath } from "./Graph.js";
import store from "./store";

const API_URL =
  "https://inpv60nmkg.execute-api.us-east-1.amazonaws.com/dev/ping";

function Garage(props) {
  // Hook into moving parts of the model with references
  const car = useRef();
  const platform = useRef();
  const beams = useRef();

  // Load geometry and materials from Graphics Library Binary file
  const { nodes, materials } = useLoader(GLTFLoader, "/garage.glb");

  // Manage the location of the car and the target empty space
  const node = useSelector((state) => state.node);
  const newTarget = useSelector((state) => state.newTarget);
  const dispatch = useDispatch();

  // State for making the UI responsive
  const [moving, setMoving] = useState(false);
  const [hovered, setHovered] = useState(false);

  // Helper Functions for checking if car is in position
  function outOfPosition(dim) {
    return (
      car.current.position[dim] < GRAPH[node][dim] - 0.1 ||
      car.current.position[dim] > GRAPH[node][dim] + 0.1
    );
  }

  function updateValue(dim) {
    return car.current.position[dim] < GRAPH[node][dim] ? 0.1 : -0.1;
  }

  // Update the position of the car every frame based on node
  useFrame(() => {
    if (outOfPosition("x")) {
      let deltaX = updateValue("x");
      car.current.position.x += deltaX;
    }
    if (outOfPosition("y")) {
      let deltaY = updateValue("y");
      car.current.position.y += deltaY;
      platform.current.position.y += deltaY;
    }
    if (outOfPosition("z")) {
      let deltaZ = updateValue("z");
      car.current.position.z += deltaZ;
      platform.current.position.z += deltaZ;
      beams.current.position.z += deltaZ;
    }
  });

  // Find the path and queue movements in order
  function sleepWhileMoving(target) {
    dispatch({ type: "MOVE_TO_NODE", payload: target });
    return new Promise((resolve) =>
      setTimeout(resolve, expectedDuration(target))
    );
  }

  function expectedDuration(target) {
    return (
      19 *
      [
        Math.abs(car.current.position.x - GRAPH[target].x) / 0.1,
        Math.abs(car.current.position.y - GRAPH[target].y) / 0.1,
        Math.abs(car.current.position.z - GRAPH[target].z) / 0.1,
      ].sort((a, b) => b - a)[0]
    );
  }

  async function moveThroughPath() {
    if (!moving) {
      setMoving(true);
      getRandomTarget();
      console.log(`Moving to ${newTarget}`);
      const queue = getPath(GRAPH, node, newTarget);
      while (node !== newTarget && queue.length) {
        await sleepWhileMoving(queue.shift());
      }
      setMoving(false);
    }
  }

  // Query the AWS Lambda API
  async function getRandomTarget() {
    console.log("Requesting a random empty space from server...");
    const start = Date.now();
    const response = await fetch(`${API_URL}?current=${newTarget}`).catch(
      (error) => {
        console.error("Error:", error);
      }
    );
    if (response) {
      const json = await response.json();
      dispatch({ type: "UPDATE_SPACE", payload: json.target });
      console.log(
        `Got a response from the server! The new space is: "${json.target}" (${
          Date.now() - start
        }ms)`
      );
    }
  }

  // Get a random space from the server once when the component mounts
  useEffect(() => {
    getRandomTarget();
    // eslint-disable-next-line
  }, []);

  // Model subcomponents that are reused in the scene
  function Beam({ position }) {
    return (
      <mesh
        material={materials["Material.002"]}
        geometry={nodes.Beam1.geometry}
        position={position}
      />
    );
  }

  function Space({ position }) {
    return (
      <mesh
        material={materials["Material.003"]}
        geometry={nodes.Level_1.geometry}
        position={position}
      />
    );
  }

  return (
    <group {...props} dispose={null}>
      <BillboardText
        color={moving ? "black" : "white"}
        scale={hovered && !moving ? [0.11, 0.11, 0.11] : [0.1, 0.1, 0.1]}
        position={[7.9, 7.8, 7.9]}
        textAlign="center"
        maxWidth={0.6}
        rotation={[0, 0, 0.85]}
      >
        {moving ? "Moving..." : "Random Shift"}
      </BillboardText>
      <Billboard
        rotation={[0, 0, 0.85]}
        position={[7.8, 7.6, 7.8]}
        scale={
          moving
            ? [0.09, 0.03, 0.09]
            : hovered
            ? [0.11, 0.06, 0.11]
            : [0.1, 0.05, 0.1]
        }
        onClick={moving ? () => {} : moveThroughPath}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        <meshStandardMaterial
          attach="material"
          color={moving ? "grey" : hovered ? "green" : "red"}
        />
      </Billboard>

      <Space position={[-7.02, 1.02, 0]} />
      <Space position={[-7.02, 1.02, 4]} />
      <Space position={[-7.02, 5.02, 0]} />
      <Space position={[-7.02, 5.02, 4]} />
      <group ref={beams}>
        <Beam position={[-1.23, 1.02, 0.03]} />
        <Beam position={[-1.23, 1.02, -2.97]} />
        <Beam position={[4.55, 1.02, 0.03]} />
        <Beam position={[4.51, 1.02, -2.97]} />
      </group>
      <mesh
        ref={platform}
        material={materials["Material.002"]}
        geometry={nodes.ElevatorPlatform.geometry}
        position={[-1.18, 1.11, -0.49]}
      />
      <group ref={car}>
        <mesh
          material={materials["Material.004"]}
          geometry={nodes.Crysler.geometry}
          position={[-5.02, 0.85, -0.65]}
          rotation={[0, 1.57, 0]}
        >
          <mesh
            material={materials["Material.004"]}
            geometry={nodes.Tire_front_L_3.geometry}
            position={[0, -0.28, 0.2]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={[0.01, 0.01, 0.01]}
          />
        </mesh>
      </group>
    </group>
  );
}

export default function Demo() {
  return (
    <Canvas camera={{ position: [8, 5, 8] }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Provider store={store}>
        <Suspense fallback={null}>
          <Garage position={[0, -3, 0]} />
        </Suspense>
      </Provider>
    </Canvas>
  );
}
