import { createStore } from "redux";

const initialState = {
  node: "s1",
  newTarget: "s1",
};

function parkingReducer(state = initialState, action) {
  switch (action.type) {
    case "MOVE_TO_NODE":
      return {
        ...state,
        node: action.payload,
      };
    case "UPDATE_SPACE":
      return {
        ...state,
        newTarget: action.payload,
      };
    default:
      return state;
  }
}

export default createStore(parkingReducer);
