import React from "react";
import { useHistory } from "react-router-dom";
import parkingDemo from "./images/parking_demo.png";
import partsWork from "./images/parts-work-demo.png";
import pong from "./images/pong.png";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PROJECTS = [
  {
    id: 1,
    name: "Parts Work Demo",
    description: "Skills: Lamdera, Elm, SVG, Animation",
    url: "https://github.com/jwhite927/parts-work",
    image: partsWork,
  },
  {
    id: 2,
    name: "Elm Pong Clone",
    description: "Skills: Elm",
    url: "https://github.com/jwhite927/elm-pong-clone",
    image: pong,
  },
];

export default function Projects() {
  const history = useHistory();

  return (
    <section className="hero is-info">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Projects </h1>
          <h2 className="subtitle">Check out some of the work I've done: </h2>

          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-image">
                  <div className="hero is-dark">
                    <div className="hero-body">
                      <figure className="image is-3by2">
                        <img src={parkingDemo} alt="parking_demo" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div className="title is-5 has-text-black">
                    Three.js Parking Demo
                  </div>
                  <div className="content">
                    Skills: React, three.js, react-three-fiber
                  </div>
                  <button
                    className="button is-primary"
                    onClick={() => history.push("/parking")}
                  >
                    View Demo
                  </button>
                </div>
              </div>
            </div>

            {PROJECTS.map((doc) => (
              <ProjectCard key={doc.id} {...doc}></ProjectCard>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function ProjectCard({ image, name, description, url }) {
  return (
    <div className="column">
      <div className="card">
        <div className="card-image p-3">
          <div className="hero is-dark">
            <div className="hero-body">
              <figure className="image is-3by2">
                <img src={image} alt="parking_demo" />
              </figure>
            </div>
          </div>
        </div>
        <div className="card-content">
          <div className="title is-5 has-text-black">{name}</div>
          <div className="content">{description}</div>
          <a
            className="button is-warning"
            rel="noopener noreferrer"
            href={url}
            target="_blank"
          >
            <FontAwesomeIcon icon={faGithub} className="mr-2" /> View on GitHub
          </a>
        </div>
      </div>
    </div>
  );
}
