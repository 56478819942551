import React, { useRef } from "react";
import { Text } from "drei";
import { useFrame } from "react-three-fiber";

export function BillboardText(props) {
  const ref = useRef();
  useFrame(({ camera }) => {
    ref.current.lookAt(camera.position);
  });

  return <Text ref={ref} {...props} />;
}
