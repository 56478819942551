import React, { useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Contact from "./Contact";
import Projects from "./Projects";
import Parking from "./parking/Parking";
import person_sitting from "./images/person_sitting.png";
import { Link } from "react-scroll";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function App() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <header className="header_area">
        <div className="main-menu">
          <nav
            className="navbar has-shadow is-spaced"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <div className="navbar-item" onClick={() => history.push("/")}>
                  <FontAwesomeIcon icon={faSun} className="fa-2x" />
                </div>
                <div
                  role="button"
                  className={`navbar-burger burger ${
                    burgerOpen ? "is-active" : ""
                  }`}
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarItems"
                  onClick={() => setBurgerOpen(!burgerOpen)}
                >
                  <span
                    style={{
                      width: "22px",
                      height: "3px",
                      borderRadius: "1px",
                    }}
                    aria-hidden="true"
                  ></span>
                  <span
                    style={{
                      width: "22px",
                      height: "3px",
                      borderRadius: "1px",
                    }}
                    aria-hidden="true"
                  ></span>
                  <span
                    style={{
                      width: "22px",
                      height: "3px",
                      borderRadius: "1px",
                    }}
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
              <div
                id="navbarItems"
                className={`navbar-menu ${burgerOpen ? "is-active" : ""}`}
              >
                <div className="navbar-start">
                  {location.pathname !== "/" ? (
                    <div
                      className="navbar-link is-arrowless navbar-item is-uppercase"
                      onClick={() => history.push("/")}
                    >
                      Back
                    </div>
                  ) : (
                    <>
                      <Link
                        className="navbar-item is-uppercase"
                        to="project-section"
                        smooth={true}
                        duration={500}
                      >
                        Projects
                      </Link>
                      <Link
                        className="navbar-item is-uppercase"
                        to="contact-section"
                        smooth={true}
                        duration={500}
                      >
                        Contact
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <Switch>
        <Route path="/parking">
          <Parking />
        </Route>

        <Route exact path="/">
          <main className="site-main">
            <div className="container">
              <div
                className="notification is-size-4 is-clickable has-text-white"
                onClick={() => history.push("/parking")}
              >
                <strong className="ml-5">New!</strong> Click here to view a
                parking demo with react-three-fiber!
              </div>
            </div>

            <section className="site-banner pr-3 pl-3">
              <div className="container pt-6">
                <div className="columns is-vcentered">
                  <div className="column site-title">
                    <h1 className="title is-2 is-uppercase">Josh White</h1>
                    <h1 className="title is-4 is-uppercase">
                      An Enthusiastic and Pragmatic Software Developer
                    </h1>
                    <a
                      href="https://drive.google.com/uc?export=download&id=1yvAsvkg4nwunN02qodHzJbWvYb59mDbM"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button is-primary is-uppercase"
                    >
                      Download Resume
                    </a>
                  </div>
                  <div className="column">
                    <img src={person_sitting} alt="banner" />
                  </div>
                </div>
              </div>
            </section>

            <section id="contact-section">
              <Contact />
            </section>
            <div id="project-section" className="pt-6">
              <Projects />
            </div>
          </main>
        </Route>
      </Switch>
    </>
  );
}
