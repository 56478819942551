export const GRAPH = {
  s1: {
    neighbors: ["p1"],
    x: 0,
    y: 0,
    z: 0,
    boxLoc: [-5.61, 0.67, 1],
  },
  s2: {
    neighbors: ["p2"],
    x: 0,
    y: 4,
    z: 0,
    boxLoc: [-5.61, 4.67, 1],
  },
  s3: {
    neighbors: ["p3"],
    x: 0,
    y: 0,
    z: 4,
    boxLoc: [-5.61, 0.67, 5],
  },
  s4: {
    neighbors: ["p4"],
    x: 0,
    y: 4,
    z: 4,
    boxLoc: [-5.61, 4.67, 5],
  },
  p1: {
    neighbors: ["s1", "p3", "p2", "p4"],
    x: 6,
    y: 0,
    z: 0,
    boxLoc: [5, 0.67, 1],
  },
  p2: {
    neighbors: ["p1", "p3", "s2", "p4"],
    x: 6,
    y: 4,
    z: 0,
    boxLoc: [5, 4.67, 1],
  },
  p3: {
    neighbors: ["p1", "p2", "p4", "s3"],
    x: 6,
    y: 0,
    z: 4,
    boxLoc: [5, 0.67, 5],
  },
  p4: {
    neighbors: ["p1", "p2", "p3", "s4"],
    x: 6,
    y: 4,
    z: 4,
    boxLoc: [5, 4.67, 5],
  },
};

function forkPath(graph, nodeArray) {
  return graph[nodeArray[nodeArray.length - 1]].neighbors.map((node) =>
    nodeArray.concat(node)
  );
}

export function getPath(graph, start, target) {
  var paths = [[start]];
  while (paths.every((path) => path[path.length - 1] !== target)) {
    paths = paths
      .flatMap((path) => forkPath(graph, path))
      .filter((path) => !path.slice(0, -1).includes(path.slice(-1)));
  }
  return paths.filter(
    (path) => path[0] === start && path[path.length - 1] === target
  )[0];
}
